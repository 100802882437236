import Club from './Components/Club/Club.js'
import Donate from './Components/Donate/Donate.js'
import Faq from './Components/FAQ/Faq'
import Feedbacks from './Components/Feedbacks/Feedbacks.js'
import Footer from './Components/Footer/Footer.js'
import Header from './Components/Header/Header'
import Marafon from './Components/Marafon/Marafon.js'
import Media from './Components/Media/Media.js'
import Offers from './Components/Offers/Offers.js'
import OurCases from './Components/OurCases/OurCases'
import OurServices from './Components/OurServices/OurServices.js'
import QuestionForm from './Components/QuestionForm/QuestionForm.js'
import SchoolMPlus from './Components/SchoolMPlus/SchoolMPlus.js'
import TeachersAdvantages from './Components/TeachersAdvantages/TeachersAdvantages.js'
import TutoringCenter from './Components/TutoringCenter/TutoringCenter'
import Vacancies from './Components/Vacancies/Vacancies.js'

function App() {
	return (
		<div className='App'>
			<Header />
			<TutoringCenter />
			<OurServices />
			<Offers />
			<Club />
			<Marafon />
			<SchoolMPlus />
			<OurCases />
			<TeachersAdvantages />
			{/* <Owner /> */}
			<Vacancies />
			<Feedbacks />
			<Media />
			<Faq />
			<Donate />
			<QuestionForm />
			<Footer />
		</div>
	)
}

export default App
